import React, { useState } from "react";

// Components
import FilterLandingPage from "./FilterLandingPage";
import JournalList from "../Journal/JournalList";

// Styles
import "../../styles/landingPage.css";

const BodyLandingPage = (props) => {
  const {
    filterValue,
    setFilterValue,
    isSearchBtnClicked,
    setIsSearchBtnClicked,
    pathname,
    categories,
    topics,
    getJournals,
    journals,
    sidebarFilterVal,
    setSidebarFilterVal,
    forceUpdate,
    handleChangePage,
    handleFilterSortBy,
    isClickedFilterSortbyBtn,
    setIsClickedFilterSortbyBtn,
    navigate
  } = props;

  const [isOpenDropdownCategory, setIsOpenDropdownCategory] = useState(false);
  const [isOpenDropdownTopic, setIsOpenDropdownTopic] = useState(false);

  const handleDropdownFilter = (key, val) => {
    if (key === "Category") {
      setFilterValue({
        ...filterValue,
        categoryIds: [val.id],
        categoryName: val.name,
      });
    } else {
      setFilterValue({
        ...filterValue,
        topicIds: [val.id],
        topicName: val.name,
      });
    }
    let finalSidebarFilter = sidebarFilterVal;
    finalSidebarFilter.forEach((filter) => {
      const { optionName, optionValue } = filter;
      if (key === optionName) {
        for (let l = 0; l < optionValue.length; l++) {
          const { label } = optionValue[l];
          if (label === val.name) {
            optionValue[l].isSelected = true;
          } else {
            optionValue[l].isSelected = false;
          }
        }
      }
    });
    setSidebarFilterVal(finalSidebarFilter);
    setIsOpenDropdownCategory(false);
    setIsOpenDropdownTopic(false);
    forceUpdate();
  };

  const handleOnClickSidebarFilter = (key1, key2, val) => {
    let finalSidebarFilterVal = sidebarFilterVal;
    finalSidebarFilterVal.forEach((sidebarVal) => {
      const { optionName, optionValue } = sidebarVal;
      if (key1 === optionName) {
        for (let l = 0; l < optionValue.length; l++) {
          const { label } = optionValue[l];
          if (label === key2) {
            optionValue[l].isSelected = val;
          }
        }
      }
      if (val === false) {
        if (key1 === "Category") {
          setFilterValue({ ...filterValue, categoryName: "" });
        } else {
          setFilterValue({ ...filterValue, topicName: "" });
        }
      }
    });
    setSidebarFilterVal(finalSidebarFilterVal);
    getJournals();
    forceUpdate();
  };

  return (
    <div className="bodyLandingPageContainer">
      <FilterLandingPage
        journals={journals}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        setIsSearchBtnClicked={setIsSearchBtnClicked}
        isSearchBtnClicked={isSearchBtnClicked}
        isOpenDropdownCategory={isOpenDropdownCategory}
        isOpenDropdownTopic={isOpenDropdownTopic}
        categories={categories}
        topics={topics}
        getJournals={getJournals}
        setIsOpenDropdownTopic={setIsOpenDropdownTopic}
        setIsOpenDropdownCategory={setIsOpenDropdownCategory}
        handleDropdownFilter={handleDropdownFilter}
        pathname={pathname}
      />
      <JournalList
        journals={journals}
        isSearchBtnClicked={isSearchBtnClicked}
        pathname={pathname}
        sidebarFilterVal={sidebarFilterVal}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        isClickedFilterSortbyBtn={isClickedFilterSortbyBtn}
        handleOnClickSidebarFilter={handleOnClickSidebarFilter}
        setIsClickedFilterSortbyBtn={setIsClickedFilterSortbyBtn}
        handleChangePage={handleChangePage}
        handleFilterSortBy={handleFilterSortBy}
        navigate={navigate}
      />
    </div>
  );
};

export default BodyLandingPage;
