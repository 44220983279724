function getAdminClientURL() {
    const localhost = "http://localhost:3131";
    const staging = "https://journal-admin.cloudxier.com"
    const production = "https://journal-admin.sustainability.co.id";
    let host = window.location.host;
    if (host) {
      host = host.toLowerCase();
    }
    let env;
    switch (host) {
      case "journal-client.cloudxier.com":
        env = staging;
        break;
      case "journal.sustainability.co.id":
        env = production;
        break;
      default:
        env = localhost;
    }
    return env;
  }
  
  module.exports = getAdminClientURL;
  