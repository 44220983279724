import React from "react";

// React DOM
import { useNavigate } from "react-router-dom";

// Icons
import TwitterLogo from "../assets/icons/TwitterLogo.png";
import FacebookLogo from "../assets/icons/FacebookLogo.png";
import InstagramLogo from "../assets/icons/InstagramLogo.png";
import LinkedInLogo from "../assets/icons/LinkedInLogo.png";
import YoutubeLogo from "../assets/icons/YoutubeLogo.png";

const Footer = () => {
  const navigate = useNavigate()

  const handleOpenPage = (url) => {
    return navigate(url);
  };

  return (
    <div
      id="footer"
      style={{
        width: "100%",
        height: "auto",
        margin: 0,
        padding: 0,
        overflowX: "hidden",
      }}
    >
      <div className="footerContainer">
        <div className="footerNavigationContainer">
          <div className="footerNavigationMain">
            <div className="footerNavigationMainList">
              <ul className="list-group list-group-vertical">
                <li
                  className="list-group-item bg-transparent border-0"
                  style={{ fontWeight: "700" }}
                >
                  About BSSE
                </li>
                <li
                  className="list-group-item bg-transparent border-0"
                  // onClick={() => navigate(`/About/Company`)}
                >
                  About us
                </li>
                <li
                  className="list-group-item bg-transparent border-0"
                  // onClick={() => navigate(`/About/TeamMembers`)}
                >
                  All Research Publications
                </li>
                <li
                  className="list-group-item bg-transparent border-0"
                  onClick={() => handleOpenPage("/article-processing-charges")}
                >
                  Article processing charges
                </li>
                <li className="list-group-item bg-transparent border-0">
                  Research Integrity
                </li>
              </ul>
              <ul className="list-group list-group-vertical">
                <li
                  className="list-group-item bg-transparent border-0"
                  style={{ fontWeight: "700" }}
                >
                  Guidelines
                </li>
                <li
                  className="list-group-item bg-transparent border-0"
                  // onClick={() => navigate(`/About/Company`)}
                >
                  Author guidelines
                </li>
                <li
                  className="list-group-item bg-transparent border-0"
                  // onClick={() => navigate(`/About/TeamMembers`)}
                >
                  Editor guidelines
                </li>
                <li className="list-group-item bg-transparent border-0">
                  Reviewer guidelines
                </li>
              </ul>
              <ul className="list-group list-group-vertical">
                <li
                  className="list-group-item bg-transparent border-0"
                  style={{ fontWeight: "700" }}
                >
                  Information
                </li>
                <li
                  className="list-group-item bg-transparent border-0"
                  // onClick={() => navigate(`/About/Company`)}
                >
                  Help center
                </li>
                <li
                  className="list-group-item bg-transparent border-0"
                  // onClick={() => navigate(`/About/TeamMembers`)}
                >
                  Publish with us
                </li>
                <li className="list-group-item bg-transparent border-0">
                  Contact us
                </li>
                <li className="list-group-item bg-transparent border-0">
                  Career opportunities
                </li>
                <li className="list-group-item bg-transparent border-0">
                  Policies and Publication Ethics
                </li>
              </ul>
            </div>
            <div className="footerNavigationSubscribe">
              <div className="footerNavigationSubscribeSocialMedia">
                <a
                  href="https://twitter.com/isa_company"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={TwitterLogo} alt="twitter" />
                </a>
                <a
                  href="https://www.facebook.com/isa.sustainability"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={FacebookLogo} alt="facebook" />
                </a>
                <a
                  href="https://www.instagram.com/isa.sustainability"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={InstagramLogo} alt="instagram" />
                </a>
                <a
                  href="https://www.linkedin.com/company/institute-for-sustainability-and-agility/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={LinkedInLogo} alt="linkedIn" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCWsQCHf9PodPl-_V_GaYorg/featured"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={YoutubeLogo} alt="youtube" />
                </a>
              </div>
            </div>
          </div>
          <div className="footerCopyright">
            <div className="footerCopyrightContainer">
              <div>
                <p>
                  © {new Date().getUTCFullYear().toString()} Business
                  Sustainability Social Enterprise Platform. All Rights
                  Reserved.
                </p>
              </div>
              <div>
                <ul
                  className="list-group list-group-horizontal bigScreen"
                  style={{ cursor: "pointer" }}
                >
                  <li
                    className="list-group-item bg-transparent border-0"
                    //   onClick={() => navigate(`/Policy/PrivacyStatement`)}
                  >
                    Privacy Policy
                  </li>
                  <li className="list-group-item bg-transparent border-0">
                    Terms
                  </li>
                  <li
                    className="list-group-item bg-transparent border-0"
                    //   onClick={() => navigate(`/Policy/Cookies`)}
                  >
                    Cookie Policy
                  </li>
                  <li
                    className="list-group-item bg-transparent border-0"
                    //   onClick={() => navigate(`/Policy/Sustainability`)}
                  >
                    Sustainability Policy
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
