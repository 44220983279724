import React from "react";

// Moment.js
import moment from "moment";

// HTML react parser
import parse from "html-react-parser";

// Icons
import orchidLogo from "../../assets/icons/orchidLogo.png";
import iconUser from "../../assets/icons/iconUser.png";
import InfoIcon from "../../assets/icons/InfoIcon.png";
import sortByIcon from "../../assets/icons/sortByIcon.png";
import arrowDownIcon from "../../assets/icons/arrowDownIcon.png";
import arrowUpIcon from "../../assets/icons/arrowUpIcon.png";
import arrowLeftIcon from "../../assets/icons/arrowLeftIcon.png";
import arrowRightIcon from "../../assets/icons/arrowRightIcon.png";
import dummy_journal_cover_1 from "../../assets/icons/dummy_journal_cover_1.png";
// import dummy_journal_cover_2 from "../../assets/icons/dummy_journal_cover_2.png";
// import dummy_journal_cover_3 from "../../assets/icons/dummy_journal_cover_3.png";
// import dummy_journal_cover_4 from "../../assets/icons/dummy_journal_cover_4.png";

// Style
import "../../styles/journal.css";

const JournalList = (props) => {
  const {
    journals,
    isSearchBtnClicked,
    pathname,
    handleOnClickSidebarFilter,
    sidebarFilterVal,
    filterValue,
    isClickedFilterSortbyBtn,
    setIsClickedFilterSortbyBtn,
    handleChangePage,
    handleFilterSortBy,
    navigate,
  } = props;

  if (isSearchBtnClicked || pathname.includes("/research-publications")) {
    return (
      <div className="journalContainer">
        {journals.length > 0 && (
          <div className="existJournalLabel">
            <div className="existJournalLabelTitle">
              {pathname.includes("/research-publications") && !isSearchBtnClicked
                ? `Displaying all Research Publications`
                : `Search results (${journals.length} Research Publication${
                    journals.length > 1 ? "s" : ""
                  } found)`}
            </div>
            <div className="paginationAndFilterSortbyContainer">
              <div className="paginationContainer">
                <div className="paginationLabel">Results per page</div>
                <div className="paginationBodyContainer">
                  <div className="paginationBody">
                    <div className="paginationValue">
                      {filterValue && filterValue.limit}
                    </div>
                    <div>
                      <img
                        src={arrowDownIcon}
                        alt="arrow down icon"
                        width={12.5}
                        height={7}
                        style={{ marginTop: "-0.2em" }}
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filterSortByMainContainer">
                <div
                  className="filterSortByContainer"
                  onClick={() =>
                    setIsClickedFilterSortbyBtn(!isClickedFilterSortbyBtn)
                  }
                  style={
                    isClickedFilterSortbyBtn
                      ? { backgroundColor: "#D9D9D9" }
                      : {}
                  }
                >
                  <div className="filterSortByBody">
                    <div className="filterSortByIconAndLabel">
                      <div
                        style={{ marginTop: "-0.2em", marginRight: "0.5em" }}
                      >
                        <img
                          src={sortByIcon}
                          alt="sort by icon"
                          width={17.5}
                          height={12.5}
                        ></img>
                      </div>
                      <div className="filterSortByValue">Sort by</div>
                    </div>
                    <div>
                      <img
                        src={
                          isClickedFilterSortbyBtn ? arrowUpIcon : arrowDownIcon
                        }
                        alt={
                          isClickedFilterSortbyBtn
                            ? "arrow up icon"
                            : "arrow down icon"
                        }
                        width={12.5}
                        height={7}
                        style={
                          isClickedFilterSortbyBtn
                            ? { marginTop: "-0.25em" }
                            : {}
                        }
                      ></img>
                    </div>
                  </div>
                </div>
                {isClickedFilterSortbyBtn ? (
                  <div className="dropdownBoxSortby">
                    <div className="dropdownBodySortby">
                      <div>
                        <div
                          className="dropdownSortbyOption"
                          onClick={() => handleFilterSortBy("title", "asc")}
                        >{`Title (A-Z)`}</div>
                        <div
                          className="dropdownSortbyOption"
                          onClick={() => handleFilterSortBy("title", "desc")}
                        >{`Title (Z-A)`}</div>
                        <div
                          className="dropdownSortbyOption"
                          onClick={() =>
                            handleFilterSortBy("createdAt", "desc")
                          }
                        >
                          {`Last updated (most recent)`}
                        </div>
                        <div
                          className="dropdownSortbyOption"
                          onClick={() => handleFilterSortBy("createdAt", "asc")}
                        >
                          {`Last updated (less recent)`}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
        <div className="journalList">
          <div className="leftFilterBox">
            <div className="leftFilterBoxTitle">
              <div className="leftFilterBoxTitleLabel">Filter by</div>
            </div>
            <div className="leftFilterBoxOptions">
              {sidebarFilterVal.map((sidebarFilter, index) => {
                const { optionName, optionValue } = sidebarFilter;
                return (
                  <div key={index}>
                    <div className="leftFilterBoxOptionsTitle">
                      {optionName}
                    </div>
                    {optionValue.map((optionVal, idx) => {
                      const { label, isSelected } = optionVal;
                      return (
                        <div className="divInputCheckbox" key={idx}>
                          <input
                            type="checkbox"
                            id={label}
                            name={label}
                            onChange={({ target: { checked, name } }) =>
                              handleOnClickSidebarFilter(
                                optionName,
                                name,
                                checked
                              )
                            }
                            checked={isSelected}
                          />
                          <div>{label}</div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="journalListAfterFileteredContainer">
            {journals && Array.isArray(journals) && journals.length > 0 ? (
              journals.map((journal) => {
                const {
                  id,
                  category_name,
                  topic_name,
                  title,
                  createdAt,
                  abstract,
                  author: { email, profile: { orchid_id } = {} } = {},
                } = journal;
                const places = [
                  {
                    id: 1,
                    street_name:
                      "Department of Neurology, All India Institute of Medical Sciences, New Delhi, India",
                  },
                  {
                    id: 2,
                    street_name:
                      "DM Wayanad Institute of Medical Sciences (DM WIMS), Wayanad, India",
                  },
                ];
                return (
                  <div className="journalBoxAfterFiltered" key={id}>
                    <div>
                      <div className="journalTitleContainerAfterFiltered">
                        <div className="journalTags">
                          <div
                            className="tagBox"
                            style={{ backgroundColor: "#ffb987" }}
                          >
                            <div className="tagName">{category_name}</div>
                          </div>
                          <div
                            className="tagBox"
                            style={{ backgroundColor: "#C6E3FF" }}
                          >
                            <div className="tagName">{topic_name}</div>
                          </div>
                        </div>
                        <div
                          className="journalTitleAfterFiltered"
                          onClick={() => navigate(`/research-publications/${id}`)}
                        >
                          {title}
                        </div>
                        <div className="journalDescAfterFiltered">
                          {parse(abstract)}
                        </div>
                        <div className="journalLastUpdatedAfterFiltered">{`Last updated on: ${moment(
                          createdAt
                        ).format("DD MMMM YYYY")}`}</div>
                      </div>
                      <div className="journalOwnersAfterFiltered">
                        <div>By</div>
                        <div className="journalUserBox">
                          <div
                            style={{
                              marginTop: "-1.5px",
                              marginRight: "5px",
                            }}
                          >
                            <img
                              src={iconUser}
                              width={19}
                              height={19}
                              alt="icon user"
                            ></img>
                          </div>
                          <div>{email}</div>
                          {orchid_id ? (
                            <img
                              src={orchidLogo}
                              className="journalOrchidIdLogo"
                              alt="orchid logo"
                            ></img>
                          ) : null}
                        </div>
                      </div>
                      <div className="journalPlacesAfterFiltered">
                        {places &&
                          Array.isArray(places) &&
                          places.length > 0 &&
                          places.map((place, index) => {
                            const { id, street_name } = place;
                            return (
                              <div key={id} className="journalPlaceBox">
                                <div className="journalPlaceNumber">
                                  {index + 1}
                                </div>
                                <div className="journalPlaceStreetName">
                                  {street_name}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="emptyJournalAfterFiltered">
                <div className="emptyJournalLabel">
                  {`Search results (${journals.length} Research Publication found)`}
                </div>
                <div className="emptyJournalDesc">
                  <div style={{ marginRight: "10px" }}>
                    <img
                      src={InfoIcon}
                      width={15}
                      height={15}
                      alt="info icon"
                    ></img>
                  </div>
                  <div className="emptyJournalDescription">
                    No matches could be found. Please try another search.
                  </div>
                </div>
              </div>
            )}
            {journals &&
            Array.isArray(journals) &&
            journals.length > 0 &&
            filterValue &&
            filterValue.pageNumber &&
            filterValue.totalPage ? (
              <div className="bottomPaginationContainer">
                <div
                  className="previousPageBtnContainer"
                  onClick={() =>
                    filterValue.pageNumber !== 1
                      ? handleChangePage("prev")
                      : null
                  }
                >
                  <div>
                    <img
                      src={arrowLeftIcon}
                      alt="arrow left icon"
                      height={12.5}
                      width={7}
                      style={{ marginRight: "0.5em" }}
                    ></img>
                  </div>
                  <div style={{ marginTop: "0.075em" }}>Previous</div>
                </div>
                <div className="pageValue">{`Page ${filterValue.pageNumber} of ${filterValue.totalPage}`}</div>
                <div
                  className="nextPageBtnContainer"
                  onClick={() =>
                    filterValue.totalPage !== filterValue.pageNumber
                      ? handleChangePage("next")
                      : null
                  }
                >
                  <div style={{ marginRight: "0.5em", marginTop: "0.075em" }}>
                    Next
                  </div>
                  <div>
                    <img
                      src={arrowRightIcon}
                      alt="arrow right icon"
                      height={12.5}
                      width={7}
                    ></img>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="journalContainer">
        <div className="journalLabel">Featured Research Publications</div>
        <div className="journalList">
          {journals &&
            Array.isArray(journals) &&
            journals.length > 0 &&
            journals.map((journal) => {
              const {
                id,
                category_name,
                topic_name,
                title,
                createdAt,
                author: { email, profile: { orchid_id } = {} } = {},
              } = journal;
              const places = [
                {
                  id: 1,
                  street_name:
                    "Department of Neurology, All India Institute of Medical Sciences, New Delhi, India",
                },
                {
                  id: 2,
                  street_name:
                    "DM Wayanad Institute of Medical Sciences (DM WIMS), Wayanad, India",
                },
              ];
              return (
                <div className="journalBox" key={id}>
                  <div className="journalContent">
                    <div className="journalImgAndTitleContainer">
                      <div className="journalImg">
                        <img
                          src={dummy_journal_cover_1}
                          alt={"journalImage"}
                        ></img>
                      </div>
                      <div className="journalTitleContainer">
                        <div className="journalTags">
                          <div
                            className="tagBox"
                            style={{ backgroundColor: "#ffb987" }}
                          >
                            <div className="tagName">{category_name}</div>
                          </div>
                          <div
                            className="tagBox"
                            style={{ backgroundColor: "#C6E3FF" }}
                          >
                            <div className="tagName">{topic_name}</div>
                          </div>
                        </div>
                        <div
                          className="journalTitle"
                          onClick={() => navigate(`/research-publications/${id}`)}
                        >
                          {title}
                        </div>
                        <div className="journalLastUpdated">{`Last updated on: ${moment(
                          createdAt
                        ).format("DD MMMM YYYY")}`}</div>
                      </div>
                    </div>
                    <div className="journalOwners">
                      <div>By</div>
                      <div key={id} className="journalUserBox">
                        <div
                          style={{
                            marginTop: "-1.5px",
                            marginRight: "5px",
                          }}
                        >
                          <img
                            src={iconUser}
                            width={19}
                            height={19}
                            alt="icon user"
                          ></img>
                        </div>
                        <div>{email}</div>
                        {orchid_id ? (
                          <img
                            src={orchidLogo}
                            className="journalOrchidIdLogo"
                            alt="orchid logo"
                          ></img>
                        ) : null}
                      </div>
                    </div>
                    <div className="journalPlaces">
                      {places &&
                        Array.isArray(places) &&
                        places.length > 0 &&
                        places.map((place, index) => {
                          const { id, street_name } = place;
                          return (
                            <div key={id} className="journalPlaceBox">
                              <div className="journalPlaceNumber">
                                {index + 1}
                              </div>
                              <div className="journalPlaceStreetName">
                                {street_name}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
};

export default JournalList;
