import React from "react";

// React Router Dom
import { useLocation, useNavigate } from "react-router-dom";

// Components
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import BodyAboutBSSEInformation from "./BodyAboutBSSEInformation";
import AboutNavigation from "./AboutNavigation";
import BodyAboutBoardManagementTeam from "./BodyAboutBoardManagementTeam";

const About = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div
      style={
        pathname.includes("/board-and-management-team")
          ? { backgroundColor: "#F8F8F8" }
          : { backgroundColor: "#FFFFFF" }
      }
    >
      <Navbar />
      <AboutNavigation pathname={pathname} navigate={navigate} />
      {pathname.includes("/board-and-management-team") ? (
        <BodyAboutBoardManagementTeam />
      ) : (
        <BodyAboutBSSEInformation />
      )}
      <Footer />
    </div>
  );
};

export default About;
