import React from "react";

// Icons
import ArrowUpWhiteIcon from "../../assets/icons/ArrowUpWhiteIcon.png";
import ArrowDownWhiteIcon from "../../assets/icons/ArrowDownWhiteIcon.png";

// Style
import "../../styles/landingPage.css";

const FilterLandingPage = (props) => {
  const {
    filterValue,
    setFilterValue,
    isSearchBtnClicked,
    handleDropdownFilter,
    isOpenDropdownCategory,
    setIsOpenDropdownCategory,
    isOpenDropdownTopic,
    setIsOpenDropdownTopic,
    categories,
    topics,
    getJournals,
    pathname,
  } = props;

  const handleSearch = (e) => {
    const val = e.target.value;
    setFilterValue({ ...filterValue, searchKey: val });
  };

  return (
    <div
      className="filterLandingPageContainer"
      style={
        pathname.includes("/research-publications") === false && !isSearchBtnClicked
          ? {}
          : { marginTop: "5em" }
      }
    >
      <div className="filterLabelSearch">Search here</div>
      <input
        type="text"
        placeholder="Title / keyword"
        className="filterInputStyle"
        onChange={(e) => handleSearch(e)}
        value={filterValue.searchKey}
      ></input>
      <div style={{ width: "10.5%" }}>
        <div
          className="filterBtnDropdown"
          onClick={() => {
            setIsOpenDropdownCategory(!isOpenDropdownCategory);
            setIsOpenDropdownTopic(false);
          }}
        >
          <div className="filterDropdownValue" style={{ marginLeft: "0.5em" }}>
            {filterValue && filterValue.categoryName
              ? filterValue.categoryName
              : "by Category"}
          </div>
          <div style={{ marginRight: "0.5em" }}>
            <img
              src={
                isOpenDropdownCategory ? ArrowUpWhiteIcon : ArrowDownWhiteIcon
              }
              alt={isOpenDropdownCategory ? "arrow up icon" : "arrow down icon"}
              width={12.5}
              height={7}
              style={isOpenDropdownCategory ? { marginTop: "-0.25em" } : {}}
            ></img>
          </div>
        </div>
        {isOpenDropdownCategory ? (
          <div className="dropdownBoxCategoryAndTopic">
            <div className="dropdownBodySortby">
              <div>
                {categories &&
                  Array.isArray(categories) &&
                  categories.length > 0 &&
                  categories.map((category) => {
                    const { name, id } = category;
                    return (
                      <div
                        className="dropdownCategoryAndTopic"
                        key={id}
                        onClick={() =>
                          handleDropdownFilter("Category", category)
                        }
                      >
                        {name}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div style={{ width: "10.5%" }}>
        <div
          className="filterBtnDropdown"
          onClick={() => {
            setIsOpenDropdownTopic(!isOpenDropdownTopic);
            setIsOpenDropdownCategory(false);
          }}
        >
          <div className="filterDropdownValue" style={{ marginLeft: "0.5em" }}>
            {filterValue && filterValue.topicName
              ? filterValue.topicName
              : "by Topic"}
          </div>
          <div style={{ marginRight: "0.5em" }}>
            <img
              src={isOpenDropdownTopic ? ArrowUpWhiteIcon : ArrowDownWhiteIcon}
              alt={isOpenDropdownTopic ? "arrow up icon" : "arrow down icon"}
              width={12.5}
              height={7}
              style={isOpenDropdownTopic ? { marginTop: "-0.25em" } : {}}
            ></img>
          </div>
        </div>
        {isOpenDropdownTopic ? (
          <div className="dropdownBoxCategoryAndTopic">
            <div className="dropdownBodySortby">
              <div>
                {topics.length > 0 &&
                  topics.map((topic) => {
                    const { name, id } = topic;
                    return (
                      <div
                        className="dropdownCategoryAndTopic"
                        key={id}
                        onClick={() => handleDropdownFilter("Topic", topic)}
                      >
                        {name}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div
        className="filterBtnSearch"
        style={
          filterValue &&
          (filterValue.searchKey ||
            filterValue.categoryName ||
            filterValue.topicName)
            ? { backgroundColor: "#030084" }
            : { backgroundColor: "#ffffff" }
        }
        onClick={() => getJournals(true)}
      >
        <div
          className="filterBtnSearchLabel"
          style={
            filterValue &&
            (filterValue.searchKey ||
              filterValue.categoryName ||
              filterValue.topicName)
              ? { color: "#ffffff" }
              : { color: "#000000" }
          }
        >
          Search
        </div>
      </div>
    </div>
  );
};

export default FilterLandingPage;
