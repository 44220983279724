import React from "react";

// Images & Icons
import AlexanderM from "../../assets/icons/AlexanderM.png";
import ClarissaTan from "../../assets/icons/ClarissaTan.png";
import KarenWang from "../../assets/icons/KarenWang.png";

const BodyAboutBoardManagementTeam = () => {
  const members = [
    {
      name: "Alexander M.",
      description:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum.",
      imageUrl: AlexanderM,
    },
    {
      name: "Clarissa Tan",
      description:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum.",
      imageUrl: ClarissaTan,
    },
    {
      name: "Karen Wang",
      description:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum.",
      imageUrl: KarenWang,
    },
    {
      name: "Clarissa Tan",
      description:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum.",
      imageUrl: ClarissaTan,
    },
    {
      name: "Karen Wang",
      description:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum.",
      imageUrl: KarenWang,
    },
    {
      name: "Alexander M.",
      description:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum.",
      imageUrl: AlexanderM,
    },
    {
      name: "Karen Wang",
      description:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum.",
      imageUrl: KarenWang,
    },
    {
      name: "Alexander M.",
      description:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum.",
      imageUrl: AlexanderM,
    },
    {
      name: "Clarissa Tan",
      description:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum.",
      imageUrl: ClarissaTan,
    },
  ];
  return (
    <div className="bodyAboutContainer" style={{ marginBottom: "5em" }}>
      <div className="firstSectionAboutPage">
        <div className="whyPublishWithUsLabel" style={{ marginBottom: "1em" }}>
          Board Members
        </div>
        <div className="firstSectionAboutPageText">
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet.
        </div>
      </div>
      <div className="membersContainer">
        {members &&
          Array.isArray(members) &&
          members.length > 0 &&
          members.map((member, index) => {
            const { name, description, imageUrl } = member;
            return (
              <div key={index} className="membersBox">
                <div className="memberImageBox">
                  <img src={imageUrl} alt="members profile"></img>
                </div>
                <div className="memberName">{name}</div>
                <div className="memberDescription">{description}</div>
              </div>
            );
          })}
      </div>
      <div className="firstSectionAboutPage">
        <div className="whyPublishWithUsLabel" style={{ marginBottom: "1em" }}>
          Advisory team
        </div>
        <div className="firstSectionAboutPageText">
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet.
        </div>
      </div>
      <div className="membersContainer">
        {members &&
          Array.isArray(members) &&
          members.length > 0 &&
          members.map((member, index) => {
            const { name, description, imageUrl } = member;
            if (index < 6) {
              return (
                <div key={index} className="membersBox">
                  <div className="memberImageBox">
                    <img src={imageUrl} alt="members profile"></img>
                  </div>
                  <div className="memberName">{name}</div>
                  <div className="memberDescription">{description}</div>
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
    </div>
  );
};

export default BodyAboutBoardManagementTeam;
