import Axios from "../../helpers";

// Helpers
import formUrlQuery from "../../helpers/format-queries";

export function getAllJournals(filter) {
  //   return (dispatch) => {
  return new Promise((resolve, reject) => {
    const {
      searchKey,
      limit,
      categoryIds,
      topicIds,
      orchidUser,
      sort,
      sortViewCount,
      languageId,
      pageNumber,
    } = filter;
    let queries = {
      search: searchKey,
      offset: limit * pageNumber - limit,
      limit,
    };
    if (categoryIds) {
      queries.categoryIds = categoryIds;
    }
    if (topicIds) {
      queries.topicIds = topicIds;
    }
    if (orchidUser === true || orchidUser === false) {
      queries.orchidUser = orchidUser;
    }
    if (sort) {
      queries.sort = sort;
    }
    if (sortViewCount) {
      queries.sortViewCount = sortViewCount;
    }
    if (languageId) {
      queries.languageId = languageId;
    }
    queries = formUrlQuery(queries);
    Axios({
      method: "get",
      url: `/submissions/published?${queries}`,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
  //   };
}

export function getAllCategories() {
  //   return (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios({
      method: "get",
      url: `/categories`,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
  //   };
}

export function getAllTopics() {
  //   return (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios({
      method: "get",
      url: `/topics`,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
  //   };
}

export function getAllLanguages() {
  //   return (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios({
      method: "get",
      url: `/languages`,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
  //   };
}

export function getOneJournal(journalId) {
  //   return (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios({
      method: "get",
      url: `/submissions/published/${journalId}`,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
  //   };
}
