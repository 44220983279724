import React from "react";

const AboutNavigation = (props) => {
  const { pathname, navigate } = props;
  const navigationList = [
    { path: "/", label: "Home" },
    {
      path: pathname.includes("/board-and-management-team")
        ? "/about/board-and-management-team"
        : "/about/BSSE-information",
      label: " About",
    },
    {
      path: pathname.includes("/board-and-management-team")
        ? "/about/board-and-management-team"
        : "/about/BSSE-information",
      label: pathname.includes("/board-and-management-team")
        ? "Board and Management Team"
        : "BSSE Information",
    },
  ];
  return (
    <div className="aboutNavigationContainer">
      <div className="aboutNavigationContainer" style={{ marginLeft: 0 }}>
        {navigationList.map((list, index) => {
          const { path, label } = list;
          return (
            <div
              className="labelNavigationAboutContainer"
              key={index}
              onClick={() => navigate(path)}
            >
              <div style={{ cursor: "pointer" }}>{label}</div>
              {index !== navigationList.length - 1 ? (
                <div style={{ marginRight: "0.5em", marginLeft: "0.5em" }}>
                  /
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AboutNavigation;
