import React from "react";

// Icons
import whyPublishWithUsIcon1 from "../../assets/icons/whyPublishWithUsIcon1.png";
import whyPublishWithUsIcon2 from "../../assets/icons/whyPublishWithUsIcon2.png";
import whyPublishWithUsIcon3 from "../../assets/icons/whyPublishWithUsIcon3.png";
import whyPublishWithUsIcon4 from "../../assets/icons/whyPublishWithUsIcon4.png";

// Style
import "../../styles/landingPage.css";

const WhyPublishWithUs = () => {
  return (
    <div className="whyPublishWithUsContainer">
      <div className="whyPublishWithUsBody">
        <div className="whyPublishWithUsLabel">Why publish with us?</div>
        <div className="whyPublishWithUsContent">
          <div className="whyPublishWithUsContentBox">
            <div className="whyPublishWithUsContenIcon">
              <img
                src={whyPublishWithUsIcon1}
                width={228}
                height={228}
                alt="icon 1"
              ></img>
            </div>
            <div>
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            </div>
          </div>
          <div className="whyPublishWithUsContentBox">
            <div className="whyPublishWithUsContenIcon">
              <img
                src={whyPublishWithUsIcon2}
                width={260}
                height={173.33}
                alt="icon 2"
              ></img>
            </div>
            <div>
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            </div>
          </div>
          <div className="whyPublishWithUsContentBox">
            <div className="whyPublishWithUsContenIcon">
              <img
                src={whyPublishWithUsIcon3}
                width={217}
                height={217}
                alt="icon 3"
              ></img>
            </div>
            <div>
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            </div>
          </div>
          <div className="whyPublishWithUsContentBox">
            <div className="whyPublishWithUsContenIcon">
              <img
                src={whyPublishWithUsIcon4}
                width={246.67}
                height={185}
                alt="icon 4"
              ></img>
            </div>
            <div>
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyPublishWithUs;
