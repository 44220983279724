import React from "react";

// React Router Dom
import { useLocation, useNavigate } from "react-router-dom";

// Components
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ArticleProcessingChargesNavigation from "./ArticleProcessingChargesNavigation";

// Styles
import "../../styles/articleProcessingCharges.css";

const ArticleProcessingCharges = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tableContent = [
    {
      number: 1,
      serviceCover: "Publishing operations",
      amountInUSD: 200,
    },
    {
      number: 2,
      serviceCover: "Journal publication",
      amountInUSD: 100,
    },
    {
      number: 3,
      serviceCover: "Editorial fees",
      amountInUSD: 80,
    },
    {
      number: 4,
      serviceCover: "Marketing and communication",
      amountInUSD: 50,
    },
    {
      number: 5,
      serviceCover: "General",
      amountInUSD: 100,
    },
    {
      number: 6,
      serviceCover: "Total publication fee per article",
      amountInUSD: 530,
    },
  ];
  return (
    <div>
      <Navbar />
      <div className="articleProcessingChargesContainer">
        <div className="articleProcessingChargesUpperContent">
          <ArticleProcessingChargesNavigation
            navigate={navigate}
            pathname={pathname}
          />
          <div className="articleProcessingChargesLabel">
            Article Processing Charges
          </div>
          <div className="firstSectionAboutPage">
            <div className="firstSectionAboutPageText">
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet.
            </div>
            <div style={{ marginTop: "3em", marginBottom: "3em" }}>
              <div
                className="firstSectionAboutPageText"
                style={{ marginBottom: "0.5em" }}
              >
                Find out more :
              </div>
              <ul>
                <li style={{ color: "#5B5B5B", textDecoration: "underline" }}>
                  Accepted currencies
                </li>
                <li style={{ color: "#5B5B5B", textDecoration: "underline" }}>
                  Method of payment
                </li>
                <li style={{ color: "#5B5B5B", textDecoration: "underline" }}>
                  Lorem ipsum
                </li>
                <li style={{ color: "#5B5B5B", textDecoration: "underline" }}>
                  Lorem ipsum
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="articleProcessingChargesBodyContent">
          <div
            className="articleProcessingChargesLabel"
            style={{
              marginLeft: "0",
              marginBottom: "0.5em",
              textAlign: "left",
            }}
          >
            General information of BSSE’s Article Processing Charge (APCs)
          </div>
          <div className="articleProcessingDesc" style={{ marginTop: "1em" }}>
            Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
            Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
            dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
            dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit
            amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit
            amet lorem ipsum dolor sit amet.
          </div>
          <div className="articleProcessingDesc" style={{ marginTop: "1em" }}>
            Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
            Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
            dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
            dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit
            amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit
            amet lorem ipsum dolor sit amet.
          </div>
          {/* table here handson */}
          <div className="apcTableContainer">
            <div style={{ width: "450px" }}>
              <div
                style={{
                  fontWeight: "700",
                  // color: "#a1a1a1",
                  fontSize: "15px",
                  marginBottom: "0.75em",
                  marginTop: "0.75em",
                }}
              >
                Article Processing Charges
              </div>
            </div>
            <div>
              <table>
                <thead>
                  <tr>
                    <th className="apcTheadNumberTh">&nbsp;</th>
                    <th className="apcTheadServiceCoverTh">Service covers</th>
                    <th className="apcTheadAmountTh">Amount {"(in USD)"}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableContent.map((content, index) => {
                    const { number, serviceCover, amountInUSD } = content;
                    return (
                      <tr key={index}>
                        <td className="apcTbodyNumberTd">{number}</td>
                        <td className="apcTbodyServiceCoverTd">
                          {serviceCover}
                        </td>
                        <td className="apcTbodyAmountTd">{amountInUSD}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {/* table here handson */}
          <div className="apcPublishingOperations">
            <div className="apcPublishingOperationBold">
              Publishing operations
            </div>
            <div className="articleProcessingDesc">
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet.
            </div>
          </div>
          <div className="apcPublishingOperations">
            <div className="apcPublishingOperationBold">
              Publishing operations
            </div>
            <div className="articleProcessingDesc">
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet.
            </div>
          </div>
          <div className="apcPublishingOperations">
            <div className="apcPublishingOperationBold">
              Publishing operations
            </div>
            <div className="articleProcessingDesc">
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet.
            </div>
          </div>
          <div className="apcPublishingOperations">
            <div className="apcPublishingOperationBold">
              Publishing operations
            </div>
            <div className="articleProcessingDesc">
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet.
            </div>
          </div>
          <div className="apcPublishingOperations">
            <div className="apcPublishingOperationBold">
              Publishing operations
            </div>
            <div className="articleProcessingDesc">
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet.
            </div>
          </div>
          <div className="apcPublishingOperations">
            <div className="apcPublishingOperationBold">
              Publishing operations
            </div>
            <div className="articleProcessingDesc">
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
              dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit
              amet lorem ipsum dolor sit amet.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ArticleProcessingCharges;
