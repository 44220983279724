import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

// Components
import LandingPage from "./views/Dashboard/LandingPage";
import About from "./views/About/About";
import JournalDetail from "./views/Journal/JournalDetail";
import ArticleProcessingCharges from "./views/ArticleProcessingCharges/ArticleProcessingCharges";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense>
          <Routes>
            <Route path="/" name="Landing Page" element={<LandingPage />} />
            <Route
              path="/research-publications"
              name="Research Publications List Page"
              element={<LandingPage />}
            />
            <Route
              path="/article-processing-charges"
              name="Article Processing Charges Page"
              element={<ArticleProcessingCharges />}
            />
            <Route
              path="/research-publications/:journal_id"
              name="Research Publications Detail Page"
              element={<JournalDetail />}
            />
            <Route
              path="/about/BSSE-information"
              name="About Page"
              element={<About />}
            />
            <Route
              path="/about/board-and-management-team"
              name="About Page"
              element={<About />}
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
