import React from "react";

// Icons
import missionAndValuesIcon from "../../assets/icons/missionAndValuesIcon.png";
import lastSectionCompanyInfoAboutPage from "../../assets/icons/lastSectionCompanyInfoAboutPage.jpg";
import whyPublishWithUsIcon1 from "../../assets/icons/whyPublishWithUsIcon1.png";
import whyPublishWithUsIcon2 from "../../assets/icons/whyPublishWithUsIcon2.png";
import whyPublishWithUsIcon3 from "../../assets/icons/whyPublishWithUsIcon3.png";
import whyPublishWithUsIcon4 from "../../assets/icons/whyPublishWithUsIcon4.png";

// Style
import "../../styles/about.css";
import "../../styles/landingPage.css";

const BodyAboutBSSEInformation = () => {
  return (
    <div className="bodyAboutContainer">
      <div className="firstSectionAboutPage">
        <div className="firstSectionAboutPageText">
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet.
        </div>
        <div className="firstSectionAboutPageText" style={{ marginTop: "2em" }}>
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet.
        </div>
      </div>
      {/* <div className="ourGuidingPrinciplesContainer">
        <div className="whyPublishWithUsBody">
          <div className="whyPublishWithUsLabel">Our guiding principles</div>
          <div className="ourGuidingPrinciplesContent">
            <div className="ourGuidingPrinciplesContentBox">
              <div className="ourGuidingPrinciplesIcon"></div>
              <div className="ourGuidingPrinciplesContentText">
                Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem
                ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
              </div>
            </div>
            <div className="ourGuidingPrinciplesContentBox">
              <div className="ourGuidingPrinciplesIcon"></div>
              <div className="ourGuidingPrinciplesContentText">
                Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem
                ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
              </div>
            </div>
            <div className="ourGuidingPrinciplesContentBox">
              <div className="ourGuidingPrinciplesIcon"></div>
              <div className="ourGuidingPrinciplesContentText">
                Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem
                ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
              </div>
            </div>
            <div
              className="ourGuidingPrinciplesContentBox"
              style={{ marginRight: "0em" }}
            >
              <div className="ourGuidingPrinciplesIcon"></div>
              <div className="ourGuidingPrinciplesContentText">
                Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem
                ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="ourGuidingPrinciplesContainer">
        <div className="whyPublishWithUsBody">
          <div className="whyPublishWithUsLabel">Our guiding principles</div>
          <div className="whyPublishWithUsContent">
            <div className="whyPublishWithUsContentBox">
              <div className="whyPublishWithUsContenIcon">
                <img
                  src={whyPublishWithUsIcon1}
                  width={228}
                  height={228}
                  alt="icon 1"
                ></img>
              </div>
              <div>
                Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem
                ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
              </div>
            </div>
            <div className="whyPublishWithUsContentBox">
              <div className="whyPublishWithUsContenIcon">
                <img
                  src={whyPublishWithUsIcon2}
                  width={260}
                  height={173.33}
                  alt="icon 2"
                ></img>
              </div>
              <div>
                Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem
                ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
              </div>
            </div>
            <div className="whyPublishWithUsContentBox">
              <div className="whyPublishWithUsContenIcon">
                <img
                  src={whyPublishWithUsIcon3}
                  width={217}
                  height={217}
                  alt="icon 3"
                ></img>
              </div>
              <div>
                Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem
                ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
              </div>
            </div>
            <div className="whyPublishWithUsContentBox">
              <div className="whyPublishWithUsContenIcon">
                <img
                  src={whyPublishWithUsIcon4}
                  width={246.67}
                  height={185}
                  alt="icon 4"
                ></img>
              </div>
              <div>
                Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem
                ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="missionAndValuesContainer">
        <div className="missionAndValuesBody">
          <div className="missionAndValuesTitle">
            <div className="whyPublishWithUsLabel">Mission and Values</div>
            <div className="missionAndValuesLabel">
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
              Lorem ipsum dolor sit amet lorem ipsum dolor sit amet.
            </div>
          </div>
          <div className="missionAndValuesTitleIcon">
            <img src={missionAndValuesIcon} alt="mission and values icon"></img>
          </div>
        </div>
      </div>
      <div className="missionAndValuesDescBody">
        <div className="firstSectionAboutPageText">
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet.
        </div>
        <div className="firstSectionAboutPageText" style={{ marginTop: "2em" }}>
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet.
        </div>
        <div className="firstSectionAboutPageText" style={{ marginTop: "2em" }}>
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
          Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum
          dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet
          lorem ipsum dolor sit amet.
        </div>
      </div>
      <div className="lastSectionAboutPage">
        <div className="firstBoxLastSectionAboutPage">
          <img
            src={lastSectionCompanyInfoAboutPage}
            alt="company info last section dummy"
          ></img>
        </div>
        <div className="secondBoxLastSectionAboutPage">
          <div className="secondBoxLastSectionAboutPageTitle">
            Lorem ipsum dolor sit amet.
          </div>
          <div className="secondBoxLastSectionAboutPageDesc">
            Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
            Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
          </div>
          <div className="secondBoxLastSectionAboutPageBtn">
            <div>Click to learn more</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyAboutBSSEInformation;
