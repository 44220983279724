import React, { useState, useEffect } from "react";

// React DOM
import { useLocation, useNavigate } from "react-router-dom";

// React Slideshow Image
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Componenets
import Navbar from "../../components/Navbar";
import BodyLandingPage from "./BodyLandingPage";
import WhyPublishWithUs from "./WhyPublishWithUs";
import Footer from "../../components/Footer";

// Icons
import mainBanner from "../../assets/icons/mainBanner.jpg";
import imageSlide2 from "../../assets/icons/imageSlide2.jpg";
import imageSlide3 from "../../assets/icons/imageSlide3.jpg";

// Styles
import "../../styles/landingPage.css";

// Endpoints
import {
  getAllCategories,
  getAllJournals,
  getAllTopics,
  // getAllLanguages, // handson unhide language & view count
} from "../../store/actions/endpoints";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const LandingPage = () => {
  const forceUpdate = useForceUpdate();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState({
    searchKey: "",
    offset: 0,
    limit: 10,
    pageNumber: 1,
    totalPage: 1,
    categoryIds: null,
    topicIds: null,
    orchidUser: false,
  });
  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  // const [languages, setLanguages] = useState([]); // handson unhide language & view count
  const [journals, setJournals] = useState([]);
  const [sidebarFilterVal, setSidebarFilterVal] = useState([]);

  const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  const [isClickedFilterSortbyBtn, setIsClickedFilterSortbyBtn] =
    useState(false);

  useEffect(() => {
    getCategories();
    getTopics();
    // getLanguages(); // handson unhide language & view count
    getJournals();
  }, []);

  useEffect(() => {
    fillSidebarFilter();
  }, [
    categories.length > 0 && topics.length > 0,
    // && languages.length > 0
    // handson unhide language & view count
  ]);

  const getCategories = async () => {
    const result = await getAllCategories();
    if (result && result.status === 200) {
      setCategories(result.data);
    }
  };

  const getTopics = async () => {
    const result = await getAllTopics();
    if (result && result.status === 200) {
      setTopics(result.data);
    }
  };

  // const getLanguages = async () => {
  //   const result = await getAllLanguages();
  //   if (result && result.status === 200) {
  //     setLanguages(result.data);
  //   }
  // };
  // handson unhide language & view count

  const fillSidebarFilter = () => {
    let finalSidebarFilterVal = [];
    let arrOptionsCategory;
    let arrOptionsTopic;
    // let arrOptionsLanguage;
    // if (languages && Array.isArray(languages) && languages.length > 0) {
    //   arrOptionsLanguage = languages.map((language) => {
    //     return {
    //       id: language.id,
    //       label: language.language_name,
    //       isSelected: false,
    //     };
    //   });
    //   finalSidebarFilterVal.push({
    //     optionName: "Language published",
    //     optionValue: arrOptionsLanguage,
    //   });
    // }
    // finalSidebarFilterVal.push({
    //   optionName: "Viewed",
    //   optionValue: [
    //     { label: "Most viewed", isSelected: false },
    //     { label: "Least viewed", isSelected: false },
    //   ],
    // });
    // handson unhide language & view count
    if (categories && Array.isArray(categories) && categories.length > 0) {
      arrOptionsCategory = categories.map((category) => {
        return { id: category.id, label: category.name, isSelected: false };
      });
      finalSidebarFilterVal.push({
        optionName: "Category",
        optionValue: arrOptionsCategory,
      });
    }
    if (topics && Array.isArray(topics) && topics.length > 0) {
      arrOptionsTopic = topics.map((topic) => {
        return { id: topic.id, label: topic.name, isSelected: false };
      });
      finalSidebarFilterVal.push({
        optionName: "Topic",
        optionValue: arrOptionsTopic,
      });
    }
    finalSidebarFilterVal.push({
      optionName: "Author",
      optionValue: [
        { label: "ORCID ID members", isSelected: false },
        { label: "Others", isSelected: false },
      ],
    });
    setSidebarFilterVal(finalSidebarFilterVal);
    forceUpdate();
  };

  const getJournals = async (flagger, filter) => {
    let languageId;
    let categoryIds = [];
    let topicIds = [];
    let orchidUser;
    let sortViewCount = "";
    let sort = filter && filter.sort;
    let limit = filter && filter.limit;
    let pageNumber = filter && filter.pageNumber;
    if (sidebarFilterVal.length > 0) {
      sidebarFilterVal.forEach((filter) => {
        const { optionName, optionValue } = filter;
        for (let l = 0; l < optionValue.length; l++) {
          const { id, label, isSelected } = optionValue[l];
          if (optionName === "Language published") {
            if (
              isSelected &&
              ((optionValue[l + 1] &&
                optionValue[l + 1].isSelected === false) ||
                (optionValue[l - 1] && optionValue[l - 1].isSelected === false))
            ) {
              languageId = id;
            }
          }
          if (optionName === "Viewed") {
            if (label === "Most viewed") {
              if (
                isSelected &&
                optionValue[l + 1] &&
                optionValue[l + 1].isSelected === false
              ) {
                sortViewCount = "view_count%desc";
              }
            } else {
              if (
                isSelected &&
                optionValue[l - 1] &&
                optionValue[l - 1].isSelected === false
              ) {
                sortViewCount = "view_count%asc";
              }
            }
          }
          if (optionName === "Category") {
            if (isSelected) {
              categoryIds.push(id);
            }
          }
          if (optionName === "Topic") {
            if (isSelected) {
              topicIds.push(id);
            }
          }
          if (optionName === "Author") {
            if (label === "ORCID ID members") {
              if (
                isSelected &&
                optionValue[l + 1] &&
                optionValue[l + 1].isSelected === false
              ) {
                orchidUser = true;
              }
            } else {
              if (
                isSelected &&
                optionValue[l - 1] &&
                optionValue[l - 1].isSelected === false
              ) {
                orchidUser = false;
              }
            }
          }
        }
      });
    }
    const result = await getAllJournals({
      ...filterValue,
      languageId: languageId,
      categoryIds: categoryIds && categoryIds.length > 0 ? categoryIds : [],
      topicIds: topicIds && topicIds.length > 0 ? topicIds : [],
      orchidUser,
      sort: sort ? sort : filterValue.sort,
      sortViewCount,
      limit: limit ? limit : filterValue.limit,
      pageNumber: pageNumber ? pageNumber : filterValue.pageNumber,
    });
    if (result && result.status === 200) {
      const { data: { submissions, total_item } = {} } = result;
      setJournals(submissions);
      let finalTotalPage = Math.ceil(total_item / filterValue.limit);
      setFilterValue({
        ...filterValue,
        totalPage: finalTotalPage,
        limit: limit ? limit : filterValue.limit,
        pageNumber: pageNumber ? pageNumber : filterValue.pageNumber,
      });
      if (flagger) {
        setIsSearchBtnClicked(true);
      }
    }
  };

  const slideImages = [
    {
      url: imageSlide2,
      // caption: "Slide 2",
    },
    {
      url: imageSlide3,
      // caption: "Slide 3",
    },
  ];

  const handleChangePage = (key) => {
    let pageNumber = filterValue && filterValue.pageNumber;
    let limit = filterValue && filterValue.limit;
    if (key === "prev") {
      pageNumber -= 1;
    } else {
      pageNumber += 1;
    }
    setFilterValue({ ...filterValue, pageNumber });
    getJournals(false, { limit, pageNumber });
  };

  const handleFilterSortBy = (key1, key2) => {
    let sortValue = `${key1}%${key2}`;
    setFilterValue({
      ...filterValue,
      sort: sortValue,
    });
    setIsClickedFilterSortbyBtn(false);
    getJournals(false, { sort: sortValue });
  };

  return (
    <div>
      <Navbar setIsSearchBtnClicked={setIsSearchBtnClicked} />
      {!isSearchBtnClicked &&
        pathname.includes("/research-publications") === false && (
          <div className="bannerContainer">
            <img
              className="bannerImgStyle"
              src={mainBanner}
              alt="dummy banner"
            ></img>
          </div>
        )}
      <BodyLandingPage
        filterValue={filterValue}
        isSearchBtnClicked={isSearchBtnClicked}
        pathname={pathname}
        categories={categories}
        topics={topics}
        journals={journals}
        sidebarFilterVal={sidebarFilterVal}
        isClickedFilterSortbyBtn={isClickedFilterSortbyBtn}
        navigate={navigate}
        setFilterValue={setFilterValue}
        setSidebarFilterVal={setSidebarFilterVal}
        getJournals={getJournals}
        setIsSearchBtnClicked={setIsSearchBtnClicked}
        forceUpdate={forceUpdate}
        handleChangePage={handleChangePage}
        handleFilterSortBy={handleFilterSortBy}
        setIsClickedFilterSortbyBtn={setIsClickedFilterSortbyBtn}
      />
      {!isSearchBtnClicked &&
        pathname.includes("/research-publications") === false && (
          <WhyPublishWithUs />
        )}
      {!isSearchBtnClicked &&
        pathname.includes("/research-publications") === false && (
          <div className="sliderBanner">
            <div className="slide-container">
              <Fade>
                {slideImages.map((slideImage, index) => {
                  const { url } = slideImage;
                  return (
                    <div key={index} className="sliderImageStyle">
                      <img src={url} alt={`dummy slide ${index + 1}`}></img>
                    </div>
                  );
                })}
              </Fade>
            </div>
          </div>
        )}
      <Footer />
    </div>
  );
};

export default LandingPage;
