import React, { useEffect, useState } from "react";

// React Router Dom
import { useParams } from "react-router-dom";

// Moment.js
import moment from "moment";

// HTML react parser
import parse from "html-react-parser";

// Components
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

// Icons
import orchidLogo from "../../assets/icons/orchidLogo.png";
import iconUser from "../../assets/icons/iconUser.png";
import BlackTwitterLogo from "../../assets/icons/BlackTwitterLogo.png";
import BlackFacebookLogo from "../../assets/icons/BlackFacebookLogo.png";
import BlackLinkedInLogo from "../../assets/icons/BlackLinkedInLogo.png";
import BlackAccountProfile from "../../assets/icons/BlackAccountProfile.png";

// Apis
import { getOneJournal } from "../../store/actions/endpoints";

// Style
import "../../styles/journal.css";

const JournalDetail = () => {
  const { journal_id } = useParams();
  const [journal, setJournal] = useState(null);

  useEffect(() => {
    fetchJournal();
  }, [journal_id]);

  const relatedArticles = [
    {
      category_name: "Open Access",
      topic_name: "Immune System",
      title:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet ",
      createdAt: new Date(),
      renderDivider: true,
    },
    {
      category_name: "Open Access",
      topic_name: "Immune System",
      title:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet ",
      createdAt: new Date(),
      renderDivider: true,
    },
    {
      category_name: "Open Access",
      topic_name: "Immune System",
      title:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet ",
      createdAt: new Date(),
      renderDivider: true,
    },
    {
      category_name: "Open Access",
      topic_name: "Immune System",
      title:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet ",
      createdAt: new Date(),
    },
    {
      category_name: "Open Access",
      topic_name: "Immune System",
      title:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet ",
      createdAt: new Date(),
    },
    {
      category_name: "Open Access",
      topic_name: "Immune System",
      title:
        "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet ",
      createdAt: new Date(),
    },
  ];

  const fetchJournal = async () => {
    if (journal_id) {
      const result = await getOneJournal(journal_id);
      if (result && result.status === 200) {
        setJournal(result.data.submission);
      }
    }
  };
  const places = [
    {
      id: 1,
      street_name:
        "Department of Neurology, All India Institute of Medical Sciences, New Delhi, India",
    },
    {
      id: 2,
      street_name:
        "DM Wayanad Institute of Medical Sciences (DM WIMS), Wayanad, India",
    },
  ];

  return (
    <div>
      <Navbar />
      <div className="journalDetailContainer">
        <div
          className="journalDetailUpperContainer"
          style={{ marginTop: "-1em" }}
        >
          <div className="journalListAfterFileteredContainer">
            <div className="journalBoxAfterFiltered">
              <div className="journalTitleContainerAfterFiltered">
                <div className="journalTags">
                  <div
                    className="tagBox"
                    style={{ backgroundColor: "#ffb987" }}
                  >
                    <div className="tagName">
                      {journal && journal.category && journal.category.name}
                    </div>
                  </div>
                  <div
                    className="tagBox"
                    style={{ backgroundColor: "#C6E3FF" }}
                  >
                    <div className="tagName">
                      {journal && journal.topic && journal.topic.name}
                    </div>
                  </div>
                </div>
                <div className="journalTitleAfterFiltered">
                  {journal && journal.title}
                </div>
              </div>
              <div className="journalOwnersAfterFiltered">
                <div>By</div>
                <div className="journalUserBox">
                  <div
                    style={{
                      marginTop: "-1.5px",
                      marginRight: "5px",
                    }}
                  >
                    <img
                      src={iconUser}
                      width={19}
                      height={19}
                      alt="icon user"
                    ></img>
                  </div>
                  <div>{journal && journal.author && journal.author.email}</div>
                  {journal && journal.author && journal.author.is_active ? (
                    <img
                      src={orchidLogo}
                      className="journalOrchidIdLogo"
                      alt="orchid logo"
                    ></img>
                  ) : null}
                </div>
              </div>
              <div className="journalPlacesAfterFiltered">
                {places &&
                  Array.isArray(places) &&
                  places.length > 0 &&
                  places.map((place, index) => {
                    const { id, street_name } = place;
                    return (
                      <div key={id} className="journalPlaceBox">
                        <div className="journalPlaceNumber">{index + 1}</div>
                        <div className="journalPlaceStreetName">
                          {street_name}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="journalDetailAbstractLabel">Abstract</div>
              <div className="journalDetailAbstract">
                {journal && journal.abstract && parse(journal.abstract)}
              </div>
              <div className="journalDetailLastUpdated">
                {journal &&
                  journal.createdAt &&
                  `Last updated on: ${moment(journal.createdAt).format(
                    "DD MMMM YYYY"
                  )}`}
              </div>
              <div className="journalDetailKeyword">Keywords</div>
              <div className="journalDetailKeywordDesc">
                Lorem ipsum, lorem ipsum, lorem ipsum, lorem ipsum, orem ipsum,
                lorem ipsum, orem ipsum, lorem ipsum, orem ipsum, lorem ipsum,
                orem ipsum, lorem ipsum
              </div>
            </div>
          </div>
          <div className="journalDetailRightBox">
            <div className="journalDetailRightBoxDownloadBtn">
              <div>Download article</div>
            </div>
            <div className="journalDetailRightBoxDescription">
              <div className="journalDetailRightBoxDescriptionSection">
                <div className="journalDetailRightBoxDescriptionSectionLabel">
                  Share on
                </div>
                <div className="journalDetailRightBoxDescriptionShareOnSection">
                  <a
                    href="https://twitter.com/isa_company"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={BlackTwitterLogo} alt="twitter" />
                  </a>
                  <a
                    href="https://www.facebook.com/isa.sustainability"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={BlackFacebookLogo} alt="facebook" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/institute-for-sustainability-and-agility/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={BlackLinkedInLogo} alt="linkedIn" />
                  </a>
                </div>
              </div>
              <div className="divider"></div>
              <div className="journalDetailRightBoxDescriptionSection">
                <div className="journalDetailRightBoxDescriptionSectionLabel">
                  Article views
                </div>
                <div className="journalDetailRightBoxDescriptionSectionArticleViewCount">
                  {journal && journal.view_count}
                </div>
              </div>
              <div className="divider"></div>
              <div className="journalDetailRightBoxDescriptionSection">
                <div className="journalDetailRightBoxDescriptionSectionLabel">
                  Academic editor
                </div>
                <div className="journalDetailRightBoxDescriptionSectionAcademicEditor">
                  <div className="journalDetailRightBoxDescriptionSectionAcademicEditorImageProfile">
                    <img src={BlackAccountProfile} alt="account" />
                  </div>
                  <div className="journalDetailRightBoxDescriptionSectionAcademicEditorNameDesc">
                    <div className="journalDetailRightBoxDescriptionSectionAcademicEditorName">
                      John Doe
                    </div>
                    <div className="journalDetailRightBoxDescriptionSectionAcademicEditorDesc">
                      Lorem ipsum dolor sit amet
                    </div>
                  </div>
                </div>
                <div className="journalDetailRightBoxDescriptionSectionAcademicEditor">
                  <div className="journalDetailRightBoxDescriptionSectionAcademicEditorImageProfile">
                    <img src={BlackAccountProfile} alt="account" />
                  </div>
                  <div className="journalDetailRightBoxDescriptionSectionAcademicEditorNameDesc">
                    <div className="journalDetailRightBoxDescriptionSectionAcademicEditorName">
                      John Doe
                    </div>
                    <div className="journalDetailRightBoxDescriptionSectionAcademicEditorDesc">
                      Lorem ipsum dolor sit amet
                    </div>
                  </div>
                </div>
              </div>
              <div className="divider"></div>
              <div className="journalDetailRightBoxDescriptionSection">
                <div className="journalDetailRightBoxDescriptionSectionLabel">
                  Academic reviewer
                </div>
                <div className="journalDetailRightBoxDescriptionSectionAcademicEditor">
                  <div className="journalDetailRightBoxDescriptionSectionAcademicEditorImageProfile">
                    <img src={BlackAccountProfile} alt="account" />
                  </div>
                  <div className="journalDetailRightBoxDescriptionSectionAcademicEditorNameDesc">
                    <div className="journalDetailRightBoxDescriptionSectionAcademicEditorName">
                      John Doe
                    </div>
                    <div className="journalDetailRightBoxDescriptionSectionAcademicEditorDesc">
                      Lorem ipsum dolor sit amet
                    </div>
                  </div>
                </div>
                <div className="journalDetailRightBoxDescriptionSectionAcademicEditor">
                  <div className="journalDetailRightBoxDescriptionSectionAcademicEditorImageProfile">
                    <img src={BlackAccountProfile} alt="account" />
                  </div>
                  <div className="journalDetailRightBoxDescriptionSectionAcademicEditorNameDesc">
                    <div className="journalDetailRightBoxDescriptionSectionAcademicEditorName">
                      John Doe
                    </div>
                    <div className="journalDetailRightBoxDescriptionSectionAcademicEditorDesc">
                      Lorem ipsum dolor sit amet
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relatedArticlesContainer">
        <div className="relatedArticlesContainerBody">
          <div className="relatedArticlesLabel">Related Articles</div>
          <div className="relatedArticlesContainerList">
            {relatedArticles.map((article, index) => {
              const {
                category_name,
                topic_name,
                title,
                createdAt,
                renderDivider,
              } = article;
              return (
                <div key={index} className="relatedArticleBox">
                  <div className="journalTags">
                    <div
                      className="tagBox"
                      style={{ backgroundColor: "#ffb987" }}
                    >
                      <div className="tagName">{category_name}</div>
                    </div>
                    <div
                      className="tagBox"
                      style={{ backgroundColor: "#C6E3FF" }}
                    >
                      <div className="tagName">{topic_name}</div>
                    </div>
                  </div>
                  <div className="journalTitleAfterFiltered">{title}</div>
                  <div className="journalDetailLastUpdated">{`Last updated on: ${moment(
                    createdAt
                  ).format("DD MMMM YYYY")}`}</div>
                  {renderDivider ? <div className="divider"></div> : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JournalDetail;
