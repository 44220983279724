import React, { useState } from "react";

// React DOM
import { useNavigate } from "react-router-dom";

// Icons
import registerOrLoginIcon from "../assets/icons/registerOrLoginIcon.png";
import bsseLogo from "../assets/icons/bsseLogo.png";

// Styles
import "../styles/navbar.css";

const getAdminClientURL = require("../helpers/get-admin-client-url");

const Navbar = (props) => {
  const navigate = useNavigate()
  const { setIsSearchBtnClicked } = props;
  const [isOpenAboutOptions, setIsOpenAboutOptions] = useState(false);
  const pages = [
    {
      label: "About",
      options: [
        {
          name: "BSSE information",
          optionUrl: "/about/BSSE-information",
        },
        {
          name: "Board and management team",
          optionUrl: "/about/board-and-management-team",
        },
      ],
    },
    { label: "Research Publications", url: "/research-publications" },
    { label: "Publish with us", url: "/research-publications" },
    { label: "Guidelines", url: "/research-publications" },
    { label: "Contact us", url: "/research-publications" },
  ];
  const handleOpenNewTabLoginAdminPanel = () => {
    const url = `${getAdminClientURL()}/login`
    window.open(url, '_blank');
  }
  return (
    <div className="navbarContainer">
      <div
        className="logoSection"
        onClick={() => {
          navigate("/");
          setIsSearchBtnClicked(false);
        }}
      >
        <img src={bsseLogo} alt="bsse-logo"></img>
      </div>
      <div className="navbarItemsContainer">
        {pages.map((page, index) => {
          const { label, url, options } = page;
          if (label === "About") {
            return (
              <div
                key={index}
                className="navbarItem"
                onClick={() => setIsOpenAboutOptions(!isOpenAboutOptions)}
              >
                <div className="labelStyle">{label}</div>
                {isOpenAboutOptions && (
                  <div className="navbarAboutOptionsContainer">
                    {options.map((option, idx) => {
                      const { name, optionUrl } = option;
                      return (
                        <div
                          key={idx}
                          className="navbarAboutOptionBox"
                          onClick={() => navigate(optionUrl)}
                        >
                          <div className="navbarAboutOptionName">{name}</div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <div
                key={index}
                className="navbarItem"
                onClick={() => navigate(url)}
              >
                {label}
              </div>
            );
          }
        })}
        <div className="registerOrLoginBtn" onClick={() => handleOpenNewTabLoginAdminPanel()}>
          <img src={registerOrLoginIcon} alt="Register or Login icon"></img>
          <div>Register / Login</div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
